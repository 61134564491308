:root {
  --tw-content: "";
  --primaryBlue: #2B2E8C;
  --textBlue: #182A4E;
  --lightBlue: #6D798E;
  --mediumGrey: #454343;
  --accentBlue: #005DAC;
  --blueGrey: #42526E;
  --grey: #727272;
  --yellow: #FAAD19;
  --flatBlue: #F4F5F7;
  --borderColor: #D3D7DD;
  --borderGrey: #EBECF0;
  --titleWhite: #F0F0FF;
  --footerWhite:#FAFBFC;
  --buttonBorderGrey: #99A1B0;
  --darkGrey:#FFFFFF4D;
  --darkBlack:#191919;
}

/* .footerRevamp {
  h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
  }
} */

.tw-bg-darkBlack{
  background-color: var(--darkBlack);
}

.tw-border-darkGrey{
  border-color: var(--darkGrey) ;
}

.tw-text-borderColor{
  color: #D3D7DD;
}

.tw-bg-primaryBlue{
  background-color: var(--primaryBlue);
}

.tw-font-normal{
  font-weight: 400;
}

.tw-text-footerWhite{
  color: var(--footerWhite);
}

.tw-text-titleWhite{
  color: var(--titleWhite);
}

.tw-static {
  position: static;
}

.tw-sticky {
  position: sticky;
}

.tw-fixed {
  position: fixed;
}

.tw-absolute {
  position: absolute;
}

.tw-relative {
  position: relative;
}

.tw-hidden {
  display: none;
}

.tw-block {
  display: block;
}

.tw-invisible {
  visibility: hidden;
}

.tw-inline {
  display: inline;
}

.tw-flex {
  display: flex;
}

.tw-flex-col {
  flex-direction: column;
}

.tw-flex-wrap {
  flex-wrap: wrap;
}

.tw-grid {
  display: grid;
}

.tw-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.tw-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.tw-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.tw-grid-cols-\[332px_332px\] {
  grid-template-columns: repeat(2, 332px);
}

.tw-whitespace-nowrap {
  white-space: nowrap;
}

.tw-items-start {
  align-items: flex-start;
}

.tw-items-center {
  align-items: center;
}

.tw-justify-between {
  justify-content: space-between;
}

.tw-justify-center {
  justify-content: center;
}

.tw-justify-end {
  justify-content: flex-end;
}

.tw-uppercase {
  text-transform: uppercase;
}

.tw-text-xs {
  font-size: 12px;
  line-height: 16px;
}

.tw-text-sm {
  font-size: 14px;
  line-height: 20px;
}

.tw-text-base {
  font-size: 16px;
  line-height: 24px;
}

.tw-text-lg {
  font-size: 18px;
  line-height: 28px;
}

.tw-text-xl {
  font-size: 20px;
  line-height: 28px;
}

.tw-text-\[13px\] {
  font-size: 13px;
}

.tw-text-\[14px\] {
  font-size: 14px;
}

.tw-text-\[15px\] {
  font-size: 15px;
}

.tw-text-\[16px\] {
  font-size: 16px;
}

.tw-font-medium {
  font-weight: 500;
}

.tw-font-semibold {
  font-weight: 600;
}

.tw-font-bold {
  font-weight: 700;
}

.tw-leading-5 {
  line-height: 20px;
}

.tw-leading-6 {
  line-height: 24px;
}

.tw-leading-tight {
  line-height: 1.25rem;
}

.tw-gap-1 {
  gap: 4px;
}

.tw-gap-2 {
  gap: 8px;
}

.tw-gap-3 {
  gap: 12px
}

.tw-gap-4 {
  gap: 16px;
}

.tw-gap-5 {
  gap: 20px;
}

.tw-gap-6 {
  gap: 24px;
}

.tw-gap-x-2 {
  column-gap: 4px;
}

.tw-gap-x-4 {
  column-gap: 16px;
}

.tw-gap-x-7 {
  column-gap: 28px;
}

.tw-gap-x-8 {
  column-gap: 32px;
}

.tw-gap-x-12 {
  column-gap: 48px;
}

.tw-gap-x-\[30px\] {
  column-gap: 30px;
}

.tw-gap-y-4 {
  row-gap: 16px;
}

.tw-gap-y-5 {
  row-gap: 20px;
}

.tw-gap-y-10 {
  row-gap: 40px;
}

.tw-p-0 {
  padding: 0;
}

.tw-p-8 {
  padding: 32px;
}

.tw-pt-0 {
  padding-top: 0;
}

.tw-pt-4 {
  padding-top: 16px;
}

.tw-pt-5 {
  padding-top: 20px;
}

.tw-pt-6 {
  padding-top: 24px;
}

.tw-pt-7 {
  padding-top: 30px;
}

.tw-pt-\[52px\] {
  padding-top: 52px;
}

.tw-pr-0 {
  padding-right: 0;
}

.tw-pr-5 {
  padding-right: 20px;
}

.tw-pr-8 {
  padding-right: 32px;
}

.tw-pr-12 {
  padding-right: 48px;
}

.tw-pr-16 {
  padding-right: 64px;
}

.tw-pb-0 {
  padding-bottom: 0;
}

.tw-pb-3 {
  padding-bottom: 12px;
}

.tw-pb-4 {
  padding-bottom: 16px;
}

.tw-pb-5 {
  padding-bottom: 20px;
}

.tw-pb-6 {
  padding-bottom: 24px;
}

.tw-pb-\[92px\] {
  padding-bottom: 92px;
}

.tw-pl-0 {
  padding-left: 0;
}

.tw-pl-5 {
  padding-left: 20px;
}

.tw-pl-8 {
  padding-left: 32px;
}

.tw-pl-12 {
  padding-left: 48px;
}

.tw-px-2 {
  padding-left: 8px;
  padding-right: 8px;
}

.tw-px-4 {
  padding-left: 16px;
  padding-right: 16px;
}

.tw-px-5 {
  padding-left: 20px;
  padding-right: 20px;
}

.tw-px-7 {
  padding-left: 28px;
  padding-right: 28px;
}

.tw-px-14 {
  padding-left: 56px;
  padding-right: 56px;
}

.tw-px-\[100px\] {
  padding-left: 100px;
  padding-right: 100px;
}

.tw-py-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.tw-py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.tw-py-3 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.tw-py-4 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.tw-py-5 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.tw-py-8 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.tw-m-0 {
  margin: 0;
}

.tw-mt-1 {
  margin-top: 4px;
}

.tw-mt-2 {
  margin-top: 8px;
}

.tw-mt-3 {
  margin-top: 12px;
}

.tw-mt-4 {
  margin-top: 16px;
}

.tw-mt-5 {
  margin-top: 20px;
}

.tw-mt-6 {
  margin-top: 24px;
}

.tw-mt-7 {
  margin-top: 28px;
}

.tw-mt-9 {
  margin-top: 36px;
}

.tw-mt-14 {
  margin-top: 56px;
}

.tw-mt-\[38px\] {
  margin-top: 38px;
}

.tw-mt-\[60px\] {
  margin-top: 60px;
}

.tw-mt-auto {
  margin-top: auto;
}

.tw-mr-5 {
  margin-right: 20px;
}

.tw-mb-auto {
  margin-bottom: auto;
}

.tw-mb-1 {
  margin-bottom: 1px;
}

.tw-mb-4 {
  margin-bottom: 16px;
}

.tw-mb-5 {
  margin-bottom: 20px;
}

.tw-mb-7 {
  margin-bottom: 28px;
}

.tw-mb-\[60px\] {
  margin-bottom: 60px;
}

.tw-ml-auto {
  margin-left: auto;
}

.tw-mx-4 {
  margin-left: 16px;
  margin-right: 16px;
}

.tw-mx-5 {
  margin-left: 20px;
  margin-right: 20px;
}

.tw-mx-6 {
  margin-left: 24px;
  margin-right: 24px;
}

.tw-mx-7 {
  margin-left: 28px;
  margin-right: 28px;
}

.tw-my-3 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.tw-my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.tw-my-5 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.tw-w-full {
  width: 100%;
}

.tw-w-fit {
  width: fit-content;
}

.tw-w-screen {
  width: 100vw;
}

.tw-w-4 {
  width: 16px;
}

.tw-w-5 {
  width: 20px;
}

.tw-w-6 {
  width: 24px;
}

.tw-w-8 {
  width: 32px;
}

.tw-w-1\/4 {
  width: 25%;
}

.tw-w-1\/3 {
  width: 33.33%;
}

.tw-w-2\/3 {
  width: 66.66%;
}

.tw-w-3\/4 {
  width: 75%;
}

.tw-w-2\/5 {
  width: 40%;
}

.tw-w-3\/5 {
  width: 60%;
}

.tw-w-\[15px\] {
  width: 15px;
}

.tw-w-\[18px\] {
  width: 18px;
}

.tw-w-\[103px\] {
  width: 103px;
}

.tw-w-\[141px\] {
  width: 141px;
}

.tw-w-\[155px\] {
  width: 155px;
}

.tw-w-\[137px\] {
  width: 137px;
}

.tw-w-\[216px\] {
  width: 216px;
}

.tw-w-\[616px\] {
  width: 616px;
}

.tw-h-screen {
  height: 100vh;
}

.tw-h-full {
  height: 100%;
}

.tw-h-0\.5 {
  height: 2px;
}

.tw-h-4 {
  height: 16px;
}

.tw-h-5 {
  height: 20px;
}

.tw-h-6 {
  height: 24px;
}

.tw-h-7 {
  height: 28px;
}

.tw-h-8 {
  height: 32px;
}

.tw-h-10 {
  height: 40px;
}

.tw-h-11 {
  height: 44px;
}

.tw-h-\[80px\] {
  height: 80px;
}

.tw-h-\[15px\] {
  height: 15px;
}

.tw-h-\[18px\] {
  height: 18px;
}

.tw-h-\[40px\] {
  height: 40px;
}

.tw-h-\[52px\] {
  height: 52px;
}

.tw-h-\[60px\] {
  height: 60px;
}

.tw-h-\[100px\] {
  height: 100px;
}

.tw-h-\[576px\] {
  height: 576px;
}

.tw-text-black {
  color: #221f1e;
}

.tw-text-white {
  color: white;
}

.tw-text-footerText {
  color: #b7b7b7;
}

.tw-text-center {
  text-align: center;
}

.tw-text-nowrap {
  text-wrap: nowrap;
}

.tw-text-yellow {
  color: #ffcb37;
}

.tw-text-red {
  color: #e63a46;
}

.tw-text-white {
  color: #fff;
}

.tw-text-greyBorder {
  color: #dfdfdf;
}

.tw-bg-transparent {
  background-color: transparent;
}

.tw-bg-\[\#090909\] {
  background-color: #090909;
}

.tw-bg-\[\#191919\] {
  background-color: #191919;
}

.tw-bg-\[\#727272\] {
  background-color: #727272;
}

.tw-bg-\[\#000\] {
  background-color: #000;
}

.tw-bg-\[\#EEF5FD\] {
  background-color: #EEF5FD;
}

.tw-bg-white {
  background-color: white;
}

.tw-bg-\[rgba\(0\,0\,0\,0\.5\)\] {
  background-color: rgba(0, 0, 0, 0.5);
}

.tw-bg-red {
  background-color: #e63a46;
}

.tw-border-none {
  border-width: 0;
}

.tw-border {
  border-width: 1px;
  border-style: solid;
}

.tw-border-\[\#393939\] {
  border-color: #393939;
}

.tw-border-\[\#3E3A38\] {
  border-color: #3e3a38;
}

.tw-border-grey {
  border-color: #727272;
}

.tw-border-greyBorder {
  border-color: #dfdfdf;
}

.tw-border-red {
  border-color: #e63a46;
}

.tw-border-white {
  border-color: white;
}

.tw-border-black {
  border-color: #221f1e;
}

.tw-border-b {
  border-width: 0;
  border-bottom-width: 1px;
  border-style: solid;
}

.tw-border-r {
  border-width: 0;
  border-right-width: 1px;
  border-style: solid;
}

.tw-border-t {
  border-top-width: 1px;
  border-top-style: solid;
}

.tw-border-solid {
  border-style: solid;
}

.tw-top-\[var\(--top-for-menu-popup\)\] {
  top: var(--top-for-menu-popup);
}

.tw-top-\[var\(--padding-top-for-body\)\] {
  top: var(--padding-top-for-body);
}

.tw-top-0 {
  top: 0;
}

.tw-left-0 {
  left: 0px;
}

.tw-transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.tw-transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.bg-cover {
  background-size: cover;
}

.tw-cursor-default {
  cursor: default;
}

.-tw-z-\[1\] {
  z-index: -1;
}

.tw-z-\[-1\] {
  z-index: -1;
}

.tw-z-0 {
  z-index: 0;
}

.tw-z-\[1\] {
  z-index: 1;
}

.tw-z-\[2\] {
  z-index: 2;
}

.tw-z-\[999\]{
  z-index: 999;
}


.tw-z-\[1000\]{
  z-index: 1000;
}

.tw-object-contain {
  object-fit: contain;
}

.tw-rounded-lg {
  border-radius: 8px;
}

.tw-rounded-3xl {
  border-radius: 24px;
}

.tw-rounded-full {
  border-radius:  9999px;;
}

.tw-list-none {
  list-style-type: none;
}

.tw-backdrop-blur-\[1px\] {
  backdrop-filter: blur(1px);
}

.tw-overflow-hidden {
  overflow: hidden;
}

.tw-max-h-0 {
  max-height: 0;
}

.tw-max-h-\[2000px\] {
  max-height: 2000px;
}

.-tw-rotate-180 {
  transform:  rotate(-180deg);
}


/* MD Breakpoint */

@media (min-width: 768px) {
  .md\:tw-hidden {
    display: none;
  }

  .md\:tw-block {
    display: block;
  }

  .md\:tw-flex {
    display: flex;
  }

  .md\:tw-flex-row {
    flex-direction: row;
  }

  .md\:tw-flex-col-reverse {
    flex-direction: column-reverse;
  }

  .md\:tw-items-center {
    align-items: center;
  }

  .md\:tw-gap-5 {
    gap: 20px;
  }

  .md\:tw-gap-x-7 {
    column-gap: 28px;
  }

  .md\:tw-mt-2 {
    margin-top: 8px;
  }

  .md\:mt-2 {
    margin-top: 8px;
  }

  .md\:mt-3 {
    margin-top: 12px;
  }

  .md\:tw-mt-4 {
    margin-top: 16px;
  }

  .md\:tw-mt-14 {
    margin-top: 56px;
  }

  .md\:tw-pt-10 {
    padding-top: 40px;
  }
  
  .md\:tw-pt-16 {
    padding-top: 64px;
  }

  .md\:tw-pb-\[38px\] {
    padding-bottom: 38px;
  }

  .md\:tw-pb-0 {
    padding-bottom: 0;
  }

  .md\:tw-mt-\[38px\] {
    margin-top: 38px;
  }

  .md\:tw-mt-\[60px\] {
    margin-top: 6px;
  }

  .md\:tw-mb-1 {
    margin-bottom: 4px;
  }

  .md\:tw-mb-4 {
    margin-bottom: 16px;
  }

  .md\:tw-mb-\[60px\] {
    margin-bottom: 60px;
  }

  .md\:tw-max-w-\[65\%\] {
    max-width: 65%;
  }

  .md\:tw-opacity-100 {
    opacity: 1;
  }

  .md\:tw-text-xs {
    font-size: 12px;
    line-height: 16px;
  }

  .md\:tw-text-sm {
    font-size: 14px;
    line-height: 20px;
  }

  .md\:tw-text-base {
    font-size: 16px;
    line-height: 24px;
  }

  .hover\:md\:tw-opacity-80:hover {
    opacity: 0.8;
  }

  .hover\:md\:tw-underline:hover {
    text-decoration-line: underline;
  }

  .md\:tw-leading-5 {
    line-height: 20px;
  }

  .md\:tw-leading-6 {
    line-height: 24px;
  }
  
  .md\:tw-border-t {
    border-top-width: 1px;
    border-top-style: solid;
  }
}

/* max-md */
@media not all and (min-width: 768px) {

  .max-md\:tw-w-1\/2 {
    width: 50%;
  }

  .max-md\:tw-leading-6 {
    line-height: 24px;
  }

  .max-md\:tw-flex-col {
    flex-direction: column;
  }

  .max-md\:tw-mt-2 {
    margin-top: 8px;
  }

  .max-md\:tw-mt-3 {
    margin-top: 12px;
  }

  .max-md\:tw-mt-9 {
    margin-top: 36px;
  }

  .max-md\:tw-border-b {
    border-width: 0;
    border-bottom-width: 1px;
    border-style: solid;
  }

  .max-md\:tw-border-grey {
    border-color: #727272
  }
}

/* Tab Breakpoint */

@media (min-width: 992px) {
  .tab\:tw-hidden {
    display: none;
  }

  .tab\:tw-block {
    display: block;
  }

  .tab\:tw-flex {
    display: flex;
  }
}

/* Desktop Breakpoint */

@media (min-width: 1200px) {
  .desktop\:tw-hidden {
    display: none;
  }

  .desktop\:tw-block {
    display: block;
  }

  .desktop\:tw-flex {
    display: flex;
  }

  .desktop\:tw-text-sm {
    font-size: 14px;
    line-height: 20px;
  }

  .desktop\:tw-text-\[16px\] {
    font-size: 16px;
  }

  .after\:desktop\:tw-h-1::after {
    content: var(--tw-content);
    height: 4px;
  }

}

/* 2XL */

@media (min-width: 1536px) {
  .\32xl\:tw-w-2\/5 {
    width: 40%;
  }
}

/* AFTER */

.after\:tw-absolute::after {
  position: absolute;
}

.after\:tw-relative::after {
  position: relative;
}

.after\:tw-block::after {
  content: var(--tw-content);
  display: block;
}

.after\:tw-bg-cover::after {
  content: var(--tw-content);
  background-size: cover;
}

.after\:tw-bg-yellow::after {
  content: var(--tw-content);
  background-color: #ffcb37;
}

.after\:tw-bg-contain::after {
  content: var(--tw-content);
  background-size: contain;
}

.after\:tw-top-1\/2::after {
  content: var(--tw-content);
  top: 50%;
}

.after\:tw-right-2::after {
  content: var(--tw-content);
  right: 8px;
}

.after\:-tw-right-\[28px\]::after {
  content: var(--tw-content);
  right: -28px;
}

.after\:tw-bottom-2::after {
  content: var(--tw-content);
  bottom: 8px;
}

.after\:tw-left-0::after {
  content: var(--tw-content);
  left: 0px;
}

.after\:tw-h-0\.5::after {
  content: var(--tw-content);
  height: 2px;
}

.after\:tw-h-1::after {
  content: var(--tw-content);
  height: 4px;
}

.after\:tw-w-full::after {
  content: var(--tw-content);
  width: 100%;
}

.after\:tw-w-4::after {
  content: var(--tw-content);
  width: 16px;
}

.after\:tw-h-4::after {
  content: var(--tw-content);
  height: 16px;
}

.after\:tw-w-5::after {
  content: var(--tw-content);
  width: 20px;
}

.after\:tw-h-5::after {
  content: var(--tw-content);
  height: 20px;
}

.after\:tw-w-6::after {
  content: var(--tw-content);
  width: 24px;
}

.after\:tw-h-6::after {
  content: var(--tw-content);
  height: 24px;
}

.after\:tw-w-\[18px\]::after {
  content: var(--tw-content);
  width: 18px;
}

.after\:tw-w-\[15px\]::after {
  content: var(--tw-content);
  width: 15px;
}

.after\:tw-h-\[15px\]::after {
  content: var(--tw-content);
  height: 15px;
}

.after\:-tw-translate-y-1\/2::after {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:tw-transition-all::after {
  content: var(--tw-content);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.after\:tw-transition-opacity::after {
  content: var(--tw-content);
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.after\:tw-duration-300::after {
  content: var(--tw-content);
  transition-duration: 300ms;
}

.after\:tw-opacity-0::after {
  content: var(--tw-content);
  opacity: 0;
}

.after\:-tw-rotate-180::after {
  content: var(--tw-content);
  transform: rotate(-180deg)
}

.after\:tw-ml-auto::after {
  content: var(--tw-content);
  margin-left: auto;
}

.after\:tw-bg-\[url\(\/assets\/images\/footer\/mail\.png\)\]::after {
  content: var(--tw-content);
  background-image: url('../assets/images/footer/mail.png');
}

.after\:tw-bg-\[url\(\/assets\/images\/footer\/call\.png\)\]::after {
  content: var(--tw-content);
  background-image: url('../assets/images/footer/call.png');
}

.after\:tw-bg-\[url\(\/assets\/images\/footer\/whatsapp\.png\)\]::after {
  content: var(--tw-content);
  background-image: url('../assets/images/footer/whatsapp.png');
}

.after\:tw-bg-\[url\(\/assets\/images\/footer\/arrow_up_filled\.png\)\]::after {
  content: var(--tw-content);
  background-image: url('../assets/images/footer/arrow_up_filled.png');
}

.after\:tw-bg-\[url\(\/assets\/images\/footer\/arrow_down_thin\.png\)\]::after {
  content: var(--tw-content);
  background-image: url('../assets/images/footer/arrow_down_thin.png');
}

.after\:tw-bg-\[url\(\/assets\/images\/header\/close\.png\)\]::after {
  content: var(--tw-content);
  background-image: url('../assets/images/header/close.png');
}

.after\:tw-bg-\[url\(\/assets\/images\/header\/arrow_down_thin\.png\)\]::after {
  content: var(--tw-content);
  background-image: url('../assets/images/header/arrow_down_thin.png');
  background-size: cover;
}

/* .after\:tw-bg-\[url\(\/assets\/images\/header\/phone\.png\)\]::after {
  content: var(--tw-content);
  background-image: url('../assets/images/header/phone.png');
}

.after\:tw-bg-\[url\(\/assets\/images\/header\/mobile_menu\.png\)\]::after {
  content: var(--tw-content);
  background-image: url('../assets/images/header/mobile_menu.png');
}

.after\:tw-bg-\[url\(\/assets\/images\/header\/close\.png\)\]::after {
  content: var(--tw-content);
  background-image: url('../assets/images/header/close.png');
}

.after\:tw-bg-\[url\(\/assets\/images\/header\/arrow_down_thin\.png\)\]::after {
  content: var(--tw-content);
  background-image: url('../assets/images/header/arrow_down_thin.png');
}
 */
.after\:tw-bg-\[url\(\/assets\/images\/header\/arrow_right\.png\)\]::after {
  content: var(--tw-content);
  background-image: url('../assets/images/header/arrow_right.png');
}

/* HOVER */

.hover\:tw-text-black:hover {
  color: #221f1e;
}

.hover\:tw-text-greyBorder {
  color: #dfdfdf !important;
}

.hover\:tw-text-white:hover {
  color: white;
}

.hover\:tw-text-footerText:hover {
  color: #b7b7b7;
}

.hover\:tw-text-yellow:hover {
  color: #ffcb37;
}

.hover\:.tw-text-red:hover {
  color: #e63a46;
}

.hover\:after\:tw-opacity-100:hover::after {
  content: var(--tw-content);
  opacity: 1;
}

.hover\:tw-underline:hover {
  text-decoration-line: underline;
}

/* for investonline/dist  */
.fs-match{
  font-size: 14px !important;
}

.tw-px-3 {
  padding-left: 12px;
  padding-right: 12px;
}

.tw-text-headerlinks {
  color: #42526E !important;
}

.tw-text-login {
  color: #2B2E8C !important;
}

.tw-border-login {
  border-width: 1px;
  border-color: #EBECF0;
  border-radius: 8px;
}


.tw-text-footerLinks {
  color: #D3D7DD !important;
}

.tw-text-contacts {
  color: #FAFBFC
}

.tw-bg-blue {
  background-color: #2B2E8C;
}

.tw-border-new {
  border-color: #EBECF0
}

.tw-border-buttonBorderGrey{
  border-color: rgb(153 161 176);
}